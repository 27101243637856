import { createContext, useState } from 'react'
import { toast } from 'react-toastify'
import { createStore } from 'redux'
import getAxios, { postAxios } from './components/axios/Axios'
import request from './components/request/request'


const AuthContext = createContext({
    loginStatus: false,
    setLoginStatus: () => { },
    loginHandler: () => { },
    logoutHandler: () => { },
    whoAmI: () => { },
})

export default AuthContext

export const AuthContextProvider = ({ children }) => {
    const [loginState, setLoginState] = useState(false)
    const loginFunc = async (username, setUsername, password, setPassword) => {
        const id = toast.loading("Please wait...")
        // the required payload
        const data = {
            mobile: username,
            password: password,
            json: '1'
        }

        // Post request for user login 
        const res = await postAxios(request.login_user, data)
        console.log(res, "res");
        if (res?.data?.Result == "AUTH_OK") {  /* as per the response data structure */
            setUsername('')
            setPassword('')
            toast.update(id, { render: "Log in Successful", type: "success", isLoading: false, autoClose: '2000', toastId: 'loginToast' });
            whoAmIFunc()
        } else {
            toast.update(id, { render: "Username or password not valid", type: "error", isLoading: false, autoClose: '2000', toastId: 'loginToast' });
        }
    }
    const logoutFunc = () => {
        getAxios(request.log_out).then(() => {
            console.log("Hello hello hello")
            whoAmIFunc()
        })
    }
    const whoAmIFunc = async () => {
        const id = toast.loading()
        const res = await getAxios(request.who_am_i)
        console.log('function running', !!res.data.loggedin)
        setLoginState(!!res.data.loggedin)
        console.log('++++Login check is running in context, and login status is', !!res.data.loggedin)
    }
    const contextValue = {
        loginStatus: loginState,
        setLoginStatus: setLoginState,
        loginHandler: loginFunc,
        logoutHandler: logoutFunc,
        whoAmI: whoAmIFunc
    }
    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    )
}