import axios from 'axios'
import React, { Component, Suspense, useContext, useEffect, useState } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import request from './components/request/request'
import { useNavigate } from 'react-router-dom'
import './scss/style.scss'
import getAxios from './components/axios/Axios'
import { Navigate } from 'react-router-dom'
import "./App.scss"
import { ToastContainer } from 'react-toastify'
import { redirect } from 'react-router-dom'
import AuthContext, { AuthContextProvider } from './contextStore'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))



function App() {
  const [loginStatus, setLoginStatus] = useState('')
  const authCtx = useContext(AuthContext)
  console.log(authCtx, "******Hello hello authctx")

  
  useEffect(() => {
    authCtx.whoAmI().then((res)=>console.log(res,"res is in the then"))
    console.log("Login state is: ",authCtx.loginStatus)
    console.log('Testing useeffect 00000000000000')
    setLoginStatus(authCtx.loginStatus)
  }, [authCtx.loginStatus])

  return (

      <HashRouter>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
          theme="light"
        />
        <Suspense fallback={loading}>
          <Routes fallback={loading}>

            <Route exact path="/register" fallback={loading} name="Register Page" element={<Register />} />
            <Route exact path="/404" fallback={loading} name="Page 404" element={<Page404 />} />
            <Route exact path="/500" fallback={loading} name="Page 500" element={<Page500 />} />
            {/* <Route exact path="/login" fallback={loading} name="Login Page" element={<Login />} /> */}

            {loginStatus && <Route path="*" name="Home" fallback={loading} element={<DefaultLayout />} />}
            {!authCtx.loginStatus && <Route path="*" name="Login Page" fallback={loading} element={<Login />} />}

          </Routes>
        </Suspense>
      </HashRouter>

  )
}

export default App
