const BASE_URL = process.env.REACT_APP_BASE_URL;
const request = {
    // login_user:BASE_URL+"webadmin/login/login_action",
    login_user: BASE_URL + "app_backend/signup/login",
    list_banner: BASE_URL + "api/list_banners",
    add_banner: BASE_URL+ "api/add_banner",
    banner_location: BASE_URL+"app_backend/api/get_banner_locations",
    delete_banner: BASE_URL + "api/delete_banner",
    update_banner: BASE_URL +"api/update_banner",
    who_am_i: BASE_URL + "app_backend/signup/whoami",
    log_out: BASE_URL + "app_backend/signup/logoff",
    registered_user: BASE_URL + "api/ofbiz/users/registered_user_list?&"
    // qr_list:BASE_URL+"api/v1/admin/list_qrcodes",
    // generate_qr:BASE_URL+"api/v1/admin/generate_qrcodes",
    // exportQrCode:BASE_URL+"api/v1/admin/export_qrcodes",
    // productList:BASE_URL+"api/v1/admin/list_products",
    // batchList:BASE_URL+"api/v1/admin/list_batch"
}
export default request