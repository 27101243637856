import axios from "axios"
const instance = axios.create({
    withCredentials: true
  })
  
const getAxios=async (url,data)=>{
    try{
        const res=await instance.get(url,data)
        return res
    }catch(err){

    }
}
const postAxios=async (url,data)=>{
    try{
        const res=await instance.post(url,data,{ headers: { "Content-Type": "multipart/form-data" } })
        return res
    }catch(err){

    }
}
export default getAxios;
export {postAxios}